.active-tag{
  background: #0071cc !important;
  color: #fff !important;
  -webkit-transition: all 0.3s ease-in-out !important;
  transition: all 0.3s ease-in-out !important;
}
.blog-image-list{
  background-repeat: no-repeat;
  height: 225px;
  background-size: cover;
}
.blog-aside{
  position: sticky;
  top: 80px;
  align-self: flex-start;
}
.tagbl{
  background-color: ghostwhite;
  z-index: 999;
}
.mobile-sidebar .sidebar-section {
  list-style-type: none;
  background: white;
  position: fixed;
  z-index: 1000;
  width: 70%;
  left: 0;
  box-shadow: 5px 0 5px -2px #888;
  height: 100%;
  top: 9%;
  padding: 30px;
  display: none;
}
.mobile-sidebar .sidebar-section.show {
  display: block !important;
}
.mobile-sidebar .sidebar-section li {
  padding: 15px 0;
  font-size: 18px;
  color: #535b61;;
}
.mobile-sidebar .sidebar-section li a {
  color: #535b61;;
}
@media only screen and (max-width: 983px) {
  .desktop-sidebar{
    display: none;
  }
  .mobile-sidebar{
    display: block;
  }
}
@media only screen and (min-width: 983px) {
  .desktop-sidebar{
    display: block;
  }
  .mobile-sidebar{
    display: none;
  }
}
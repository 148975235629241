.main-section{
  padding: 0 !important;
}
.search-result{
  width: 100%;
  top: 51px;
  z-index: 1000;
  color: #535b61;
  border-radius: 0 0 5px 5px !important;
  list-style-type: none;
  background: white;
  padding-left: 0 !important;
}
.search-result > li{
  padding: 10px 10px 10px 25px;
}
.search-result > li:hover{
  background: #f4f5f4;
  cursor: pointer;
}
.client-item{
  width: 150px;
  height: 115px;
  border-radius: 7px;
}
.client-item1{
  width: 87% ;
  height: 220px ;
 
}
.client-item .img-fluid{
  display: inherit !important;
}  
#listCards ul {
  padding-left: 0;
  list-style: none;
  height:20rem;
}
.white{
  color:white
}
.blog-image{
  background-repeat: no-repeat;
  height: 161px;
  background-size: cover;
}
.search-shadow{
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.068) !important;
}
.search-tag{
  margin-right: 20px !important;
  border-radius: 14px !important;
  margin-bottom: 10px !important;
  padding:3px 8px 3px 8px !important;
  background-color: #d5ceac96 !important;
  color: #ffff !important;
}
.tag-text-center{
  text-align: center !important;
}
.textlegal{
  text-align: left !important;
}
.homepadding1{
  padding-top: 2.7rem !important;
  padding-bottom: 3rem !important;
}
@media only screen and (max-width: 320px) {
  .homepadding1 {
    padding-top: 1 rem !important;
    padding-bottom: 1 rem !important;
  }
  
}
@media(max-width: 479px) {
  
   .min-vh-100 {
    min-height: 60vh !important;
}
.client-item1{
  width: 85%;
  height: 265px;
}

}
@media(max-width:280px) {
  .client-item1{
   width:85%;
   height: 365px;
  } 
  
 }
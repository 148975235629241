.red {
  color: red
}

.primary-menu ul.navbar-nav>li.dropdown .dropdown-menu li>a:not(.btn),
.login-signup ul.navbar-nav>li.dropdown .dropdown-menu li>a:not(.btn) {
  padding: 5px 0px 5px 0px !important;
}

.serleftpadding {
  padding-left: 0.5 rem !important;
  padding-right: 1.3 rem;
}


.text3s {
  font-size: 15px !important;
}

.sub-title {
  border-bottom: 1px solid rgba(0, 183, 255, 0.904);
  padding-bottom: 3px !important;
}

.compliancefont {

  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}

.logo-main {
  line-height: 20px;
  margin-top: 10px;
  font-family: none;
  font-size: 28px !important;
}
.logo-left {
  background: #046fb4;
  color: white;
  padding: 0 8px;
  animation: 3s anim-lineUp ease-out;
}
.logo-right {
  padding: 0 8px;
  background: #ecebea;
  animation: waviy 1s infinite;
  animation: 3s anim-lineUp ease-out;
}

.logo-tm {
  font-size: 10px;
  color: black;
  top: -15px;
  right: -18px;
}

.logo-sub {
  font-size: 11px;
  color: black;
  padding-top: 1px;
  padding-right: 0;
  font-family: none;
  animation: 3s anim-lineUp ease-out;
}
.trademark{
  color: black;
  font-size: 14px;
  vertical-align: super;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.deskMargin{
  margin-left:-160px !important;
  margin-top: 15px !important;
}
@media (max-width:479px){
.deskMargin{
  margin-left: 0px !important;
}
}
.rc_selected .rc_body-cell_value{
  background: white !important;
}
.rc_selected button{
  color: black !important;
}
.column1 {
  float: left;
  width: 100%;
  padding: 15px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  color: black;
  border-left: 4px solid hsl(202, 54%, 68%)!important;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  margin: 10px;
  background-color: #f2f2f2 !important;
  font-weight: bold;
  text-align: left;
}
hr.style3 {
	border-top: 1px dashed #8c8b8b;
}
.intbtn{
  padding: 5px 10px 5px 10px;
 
}
.rc_highlight button{
  border-radius: 50%;
  height: 100% !important;
  background: #89CFF0;
  color: #0047AB !important;
}
.logo-tmft{
    font-size: 10px;
    color:white;
    top: -12px;
    right:114px;
}
.logo-subft{
    font-size: 11px;
    color: black;
    padding-top: 1px;
    padding-right: 0;
    font-family: none;
    animation: 3s anim-lineUp ease-out;
}
.logo-mainft {
    line-height: 20px;
    margin-top: 10px;
    font-family: none;
    font-size: 26px !important;
  }
  .logo-leftft {
    background: #046fb4;
    color: white;
    padding: 0 8px;
    animation: 3s anim-lineUp ease-out;
  }
  .logo-rightft {
    padding: 0 8px;
    background: #ecebea;
    animation: waviy 1s infinite;
    animation: 3s anim-lineUpft ease-out;
  }
  .white{
    color: white;
  }
  .trademarkft{
    color: white;
    font-size: 14px;
    vertical-align: super;
  }
  .footer-cat-hr{
    width: 150px;
    border-bottom: 3px solid #fff;
  }
  @keyframes anim-lineUpft {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
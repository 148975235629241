
.dbox {
  position: relative;
  background: rgb(255, 86, 65);
  background: -moz-linear-gradient(top, rgba(255, 86, 65, 1) 0%, rgba(253, 50, 97, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 86, 65, 1) 0%, rgba(253, 50, 97, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 86, 65, 1) 0%, rgba(253, 50, 97, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff5641', endColorstr='#fd3261', GradientType=0);
  border-radius: 4px;
  text-align: center;
  margin: 10px 0 10px;
}
.dbox__icon {
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
.dbox__icon:before {
  width: 75px;
  height: 75px;
  position: absolute;
  background: #fda299;
  background: rgba(253, 162, 153, 0.34);
  content: '';
  border-radius: 50%;
  left: -17px;
  top: -17px;
  z-index: -2;
}
.dbox__icon:after {
  width: 60px;
  height: 60px;
  position: absolute;
  background: #f79489;
  background: rgba(247, 148, 137, 0.91);
  content: '';
  border-radius: 50%;
  left: -10px;
  top: -10px;
  z-index: -1;
}
.dbox__icon > i {
  background: #ff5444;
  border-radius: 50%;
  line-height: 40px;
  color: #FFF;
  width: 40px;
  height: 40px;
font-size:22px;
}
.dbox__body {
  padding: 15px 15px;
}
.dbox__count {
  font-size: 25px;
  color: #FFF;
  font-weight: 300;
  line-height: 1;
}
.dbox__title {
  font-size: 16px;
  color: #FFF;
  color: rgba(255, 255, 255, 0.81);
}
.dbox__action {
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  left: 50%;
}
.dbox__action__btn {
  border: none;
  background: #FFF;
  border-radius: 19px;
  padding: 7px 16px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: .5px;
  color: #003e85;
  box-shadow: 0 3px 5px #d4d4d4;
}


.dbox--color-2 {
  background: rgb(252, 190, 27);
  background: -moz-linear-gradient(top, rgba(252, 190, 27, 1) 1%, rgba(248, 86, 72, 1) 99%);
  background: -webkit-linear-gradient(top, rgba(252, 190, 27, 1) 1%, rgba(248, 86, 72, 1) 99%);
  background: linear-gradient(to bottom, rgba(252, 190, 27, 1) 1%, rgba(248, 86, 72, 1) 99%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#fcbe1b', endColorstr='#f85648', GradientType=0);
}
.dbox--color-2 .dbox__icon:after {
  background: #fee036;
  background: rgba(254, 224, 54, 0.81);
}
.dbox--color-2 .dbox__icon:before {
  background: #fee036;
  background: rgba(254, 224, 54, 0.64);
}
.dbox--color-2 .dbox__icon > i {
  background: #fb9f28;
}

.dbox--color-3 {
  background: rgb(183,71,247);
  background: -moz-linear-gradient(top, rgba(183,71,247,1) 0%, rgba(108,83,220,1) 100%);
  background: -webkit-linear-gradient(top, rgba(183,71,247,1) 0%,rgba(108,83,220,1) 100%);
  background: linear-gradient(to bottom, rgba(183,71,247,1) 0%,rgba(108,83,220,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b747f7', endColorstr='#6c53dc',GradientType=0 );
}
.dbox--color-3 .dbox__icon:after {
  background: #b446f5;
  background: rgba(180, 70, 245, 0.76);
}
.dbox--color-3 .dbox__icon:before {
  background: #e284ff;
  background: rgba(226, 132, 255, 0.66);
}
.dbox--color-3 .dbox__icon > i {
  background: #8150e4;
}
.column {
  float: left;
  width: 40%;
  padding: 15px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  color: black;
  border-left: 4px solid #9FDC9B!important;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px;
  background-color: #f2f2f2 !important;
  font-weight: bold;
  text-align: left;
}

.containerTab {
  padding: 20px;
  color: white;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  text-align: right;
}

/* Closable button inside the container tab */
.closebtn {
  float: right;
  color: white;
  font-size: 35px;
  cursor: pointer;
}
.headingfont{
  font-size: 16px !important;
  font-weight: 500;
}
.paylink{
  background-color:palegreen;
  border-radius: 5px;
  font-size: smaller;
  font-weight: normal;
  color:darkolivegreen;
  padding-left: 5px;
  padding-right: 5px;
}
.arrow{
  font-weight: bold;
  font-size: larger;
  text-align-last: right;
}
.service-tab-content{
  height: 350px;
  overflow-y: scroll;
}
.div1{
  width: 60%;
}
.div2{
  width: 30%;
}
.div3{
  width: 10%;
}
@media(max-width: 479px) {
  
  .column {
    float: left;
    width: 75%;
    padding: 15px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    color: black;
    border-left: 4px solid #9FDC9B!important;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    background-color: #f2f2f2 !important;
    font-weight: bold;
    text-align: left;
}
}
.accordion .accordion-header .accordion-button {
  padding: 0.5rem 1.25rem 0.5rem 2.25rem !important;
}
#tab1 .list-item{
  padding-left: 0;
}
#tab1 .list-item li{
  background: #f7f7f7;
  font-weight: 700;
}
#tab1 .list-item li a:link {
  padding: 7px 0px 7px 15px;
  background-size: auto; 
  font-size: 16px;
}
.list-item li a:after {
  content: none;
}
#tab1 .list-item .item-active{
  background: white;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-left: 5px solid #0071cc;
}
#tab1 .list-item li:hover{
  background: white;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  border-left: 5px solid #0071cc;
}
.htw-section{
  padding: 1px 0px;
}
.htw-icon{
  width: 60px !important;
  height: 60px !important;
}
@media only screen and (max-width: 983px) {
  .desktop-details{
    display: none;
  }
  .mobile-details{
    display: block;
  }
}
@media only screen and (min-width: 983px) {
  .desktop-details{
    display: block;
  }
  .mobile-details{
    display: none;
  }
}